import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import Heading from './heading'
import ProficiencyTestsArchivePreview from './proficiency-tests-archive-preview'

import Button from './button'
import Pagination from './pagination'

import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.section`
  display: block;
  padding: 4rem 0;
  @media (min-width: 1024px) {
    max-width: 680px;
    margin: 0 auto;
  }

`

const List = styled.ul`
  display: block;
  margin: 0 2.8rem;
`

function ProficiencyTestsArchiveList (props) {
  const {currentPage, numPages} = props

  return (
    <Wrapper>
      <Heading title='検定試験' titleEn='Proficiency Tests' />
      <List>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node._id} >
              <ProficiencyTestsArchivePreview {...node} isInList />
            </li>
          ))}
      </List>
      <Pagination currentPage={currentPage} numPages={numPages} slug='proficiency-tests'/>
    </Wrapper>
  )
}

ProficiencyTestsArchiveList.defaultProps = {
  nodes: []
}

export default ProficiencyTestsArchiveList
