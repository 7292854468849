
import {format, distanceInWords, differenceInDays} from 'date-fns'
import ja from 'date-fns/locale/ja'

import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import {buildImageObj, getProficiencyTestsUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.div`
  display: block;
  margin: 0 0 4rem 0;
`

const ImageBg = styled.div`
  /* background-image: url(../../greenBg.svg); */
  /* background-size: 100%; */
  /* background-position: center -5px; */
  /* background-repeat: no-repeat; */
  /* padding: 0 0 20px 0; */
`

const ImageMask = styled.div`
  display: block;
  /* filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1) ); */
`

const Image = styled.img`
  border-radius: 5px;
  /* mask-image: url(../../mask_1.svg);
  mask-repeat: none;
  mask-position: bottom;
  mask-size: 100%; */
`

const ImageLink = styled(props => <Link {...props} />)`
`

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.black};
  text-decoration: none;
  font-size: 1.6rem;
`

const DateCats = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
`

const DateTime = styled.span`
  display: block;
  color: ${colors.lightNavy};
  margin: 0 0.8rem 0 0;
`

const Cats = styled.ul`
  display: flex;
  flex-direction: row;
`
const Cat = styled.li`
  color: ${colors.green};
  margin: 0 0.8rem 0 0;
`

class ProficiencyTestsArchivePreview extends React.Component {
  constructor (props) {
    super(props)
    this.url = ''
  }

  componentWillMount () {
    this.url = getProficiencyTestsUrl(this.props.publishedAt, this.props.slug.current)
  }
  render () {
    return (
      <Wrapper>
        <DateCats>
          {this.props.publishedAt && (
            <DateTime>
              {differenceInDays(new Date(this.props.publishedAt), new Date()) > 3
                ? distanceInWords(new Date(this.props.publishedAt), new Date())
                : format(new Date(this.props.publishedAt), 'YYYY年MM月DD日(dd)', {locale: ja})}
            </DateTime>
          )}
          { this.props.categories && (
            <Cats>
              {this.props.categories.map(cat => (
                <Cat key={cat._id} >{cat.title}</Cat>
              ))}
            </Cats>
          )}
        </DateCats>
        <StyledLink to={this.url}>
          {this.props.title}
        </StyledLink>
      </Wrapper>
    )
  }
}

export default ProficiencyTestsArchivePreview
