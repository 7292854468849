import React from 'react'
import {graphql, Link} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  buildImageObj
} from '../lib/helpers'

import ProficiencyTestsArchive from '../components/proficiency-tests-archive-list'

export const query = graphql`

  query ProficiencyTestsArcheveTemplateQUery($skip: Int!, $limit: Int!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      heroImage{
        ...SanityImage
        alt
      }
      title
      subTitle
      _rawIntro
      _rawFooter
      description
      keywords
    }

    tests: allSanityProficiencyTests(
      limit: $limit
      skip: $skip
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ){
      edges {
        node {
          _type
          _id
          publishedAt
          title
          categories{
            _id
            title
            catParent
            slug{
              current
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`

const ProficiencyTestsTemplate = props => {
  const {data, errors} = props

  const site = (data || {}).site
  const testsNodes = (data || {}).tests
    ? mapEdgesToNodes(data.tests)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []
  const {currentPage, numPages} = props.pageContext

  return (

    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      <SEO
        title='検定試験'
        description={site.description}
        keywords={site.keywords}
      />

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {testsNodes && <ProficiencyTestsArchive nodes={testsNodes} currentPage={currentPage} numPages={numPages}
      />}

    </Layout>
  )
}

export default ProficiencyTestsTemplate
